import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { FaHeart } from 'react-icons/fa';
import Header from '../../header/header';
import CommentModal from '../../hosting/Hosting/coment/comment';
import LikedUsersModal from '../../likeModal/likeModal';
import CommentIcon from './img/commentIcon.png';
import PostNav from './img/navigate.png';
import ActiveLike from './img/activeLike.png';
import UnactiveLike from './img/unactiveLike.png';

function CustomVideoPlayer({ src, type, onDoubleClick }) {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const togglePlayPause = () => {
        if (isPlaying) videoRef.current.pause();
        else videoRef.current.play();
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    return (
        <div 
            className="custom-video"
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
            onDoubleClick={onDoubleClick}
        >
            <video 
                ref={videoRef} 
                muted={isMuted} 
                onClick={togglePlayPause} 
                loop
                playsInline
            >
                <source src={src} type={type} />
            </video>
            <div className={`video-controls ${showControls ? 'visible' : ''}`}>
                <button onClick={togglePlayPause}>
                    {isPlaying ? '❚❚' : '►'}
                </button>
                <button onClick={toggleMute}>
                    {isMuted ? '🔇' : '🔊'}
                </button>
            </div>
        </div>
    );
}

function UserProfile() {
    const { postId } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [commentPostId, setCommentPostId] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [likedUsers, setLikedUsers] = useState([]);
    const [likedModalOpen, setLikedModalOpen] = useState(false);
    const [showHeart, setShowHeart] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const { data } = await axios.get(`/api/post/${postId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setPost(data);
            } catch (error) {
                console.error('Ошибка загрузки поста:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [postId]);

    useEffect(() => {
        const fetchLikedUsers = async () => {
            try {
                if (post?.post?.id) {
                    const token = localStorage.getItem('jwtToken');
                    const { data } = await axios.get(`/api/likes/${post.post.id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setLikedUsers(data.users);
                }
            } catch (error) {
                console.error("Ошибка получения лайков:", error);
            }
        };
        fetchLikedUsers();
    }, [post]);

    const handleLike = async (postId, liked) => {
        try {
            const token = localStorage.getItem('jwtToken');
            const url = `/api/${liked ? 'unlike' : 'like'}/${postId}`;
            
            await axios.post(url, {}, { 
                headers: { Authorization: `Bearer ${token}` } 
            });

            setPost(prev => ({
                ...prev,
                likes: !liked,
                countLike: liked ? prev.countLike - 1 : prev.countLike + 1
            }));

            setShowHeart(true);
            setTimeout(() => setShowHeart(false), 800);
        } catch (error) {
            console.error('Ошибка лайка:', error);
        }
    };

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => alert('Ссылка скопирована!'))
            .catch(() => alert('Ошибка копирования'));
    };

    const openComments = (postId) => setCommentPostId(postId);
    const closeComments = () => setCommentPostId(null);

    const handleDeletePost = async () => {
        if (window.confirm('Удалить пост навсегда?')) {
            try {
                const token = localStorage.getItem('jwtToken');
                await axios.delete(`/api/deletePost/${postId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                navigate('/myProfile');
            } catch (error) {
                console.error("Ошибка удаления:", error);
            }
        }
    };

    const formatPostDate = (dateString) => {
        if (!dateString || isNaN(new Date(dateString))) {
          return 'Недавно'; // или любое другое значение по умолчанию
        }
        return formatDistanceToNow(new Date(dateString), { addSuffix: true });
      };

    const openLikedUsers = () => setLikedModalOpen(true);
    const closeLikedUsers = () => setLikedModalOpen(false);

    if (loading) return <div className="loading">Загрузка...</div>;
    if (!post) return <div className="error">Пост не найден</div>;

    return (
        <>
            <Header />
            <div className="user-postPage">
                <div className="postPage-header">
                  <div className="postHeader-login">
                    <div className="postHeader-loginContainer">
                      <img src={post.user?.logoImage || '/default-avatar.png'} alt="Аватар" />
                    </div>
                    <Link to={`/userProfile/${post.user?.id}`}>
                      <span>{post.user?.login}</span>
                    </Link>
                  </div>
                  <div className="postHeader-nav">
                    <img src={PostNav} alt="Меню" onClick={() => setIsMenuOpen(!isMenuOpen)} />
                    {isMenuOpen && (
                      <ul className="post-menu">
                        <li onClick={copyLink}>Копировать ссылку</li>
                      </ul>
                    )}
                  </div>
                </div>

                <div className="postPage-main">
                  <div 
                    className="double-tap-overlay"
                    onDoubleClick={() => !post.likes && handleLike(post.post.id, post.likes)}
                  >
                    {showHeart && <FaHeart className="heart-animation" />}
                  </div>

                  {post.post.fileType?.startsWith('image') ? (
                    <img 
                      src={post.post.postUrl} 
                      alt="Пост" 
                      onDoubleClick={() => !post.likes && handleLike(post.post.id, post.likes)}
                    />
                  ) : (
                    <CustomVideoPlayer 
                      src={post.post.postUrl} 
                      type={post.post.fileType}
                      onDoubleClick={() => !post.likes && handleLike(post.post.id, post.likes)}
                    />
                  )}
                </div>

                <div className="postPage-footer">
                  <div className="interaction-buttons">
                    <div className="left-buttons">
                      <button 
                        onClick={() => handleLike(post.post.id, post.likes)}
                        className={`like-button ${post.likes ? 'active' : ''}`}
                      >
                        <img src={post.likes ? ActiveLike : UnactiveLike} alt="Лайк" />
                        <span>{post.post.countLike}</span>
                      </button>
                      <button onClick={() => openComments(post.post.id)}>
                        <img src={CommentIcon} alt="Комментарии" />
                        <span>{post.post.countComment}</span>
                      </button>
                    </div>
                  </div>

                  <div className="post-info">
                    <p className="description">{post.post.description}</p>
                    <time className="post-date">
                        {formatPostDate(post.createdAt)}
                    </time>
                    {likedUsers.length > 0 && (
                      <div className="liked-users">
                        <span>Нравится </span>
                        {likedUsers.slice(0, 2).map((user, index) => (
                          <React.Fragment key={user.id}>
                            <Link to={`/userProfile/${user.id}`}>{user.login}</Link>
                            {index === 0 && likedUsers.length > 1 && ', '}
                          </React.Fragment>
                        ))}
                        {likedUsers.length > 2 && (
                          <button onClick={openLikedUsers}>
                            и ещё {post.post.countLike - 2}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
            </div>

            {commentPostId && (
                <CommentModal 
                    postId={postId} 
                    onClose={closeComments} 
                />
            )}
            
            {likedModalOpen && (
                <LikedUsersModal 
                    postId={post.post.id} 
                    onClose={closeLikedUsers} 
                />
            )}
        </>
    );
}

export default UserProfile;