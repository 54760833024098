import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { FaHeart } from 'react-icons/fa';
import './hosting.scss';
import './postPage.scss';
import CommentModal from './coment/comment';
import LikedUsersModal from '../../likeModal/likeModal';
import CommentIcon from './image/commentIcon.png';
import ActiveLike from './image/activeLike.png';
import UnactiveLike from './image/unactiveLike.png';

function CustomVideoPlayer({ src, type, onDoubleClick }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  return (
    <div
      className="custom-video"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      onDoubleClick={onDoubleClick}
    >
      <video
        ref={videoRef}
        muted={isMuted}
        onClick={togglePlayPause}
        loop
        playsInline
      >
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
      <div className={`video-controls ${showControls ? 'visible' : ''}`}>
        <button className="play-pause" onClick={togglePlayPause}>
          {isPlaying ? '❚❚' : '►'}
        </button>
        <button className="volume" onClick={toggleMute}>
          {isMuted ? '🔇' : '🔊'}
        </button>
      </div>
    </div>
  );
}

function Hosting({ type }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentPostId, setCommentPostId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [expandedPosts, setExpandedPosts] = useState([]);
  const [likedPostId, setLikedPostId] = useState(null);
  const [hearthAnimations, setHearthAnimations] = useState({});
  const [likedUsersMap, setLikedUsersMap] = useState({});
  const observer = useRef();

  const fetchPosts = useCallback(async (skip = 0) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const res = await axios.get('/api/posts', {
        headers: { Authorization: `Bearer ${token}` },
        params: { skip, limit: 10, type }
      });

      setPosts(prevPosts => {
        const newPosts = [...prevPosts, ...res.data.posts];
        return [...new Map(newPosts.map(post => [post.id, post])).values()];
      });
      setLoading(false);
      setHasMore(res.data.posts.length > 0);
    } catch (error) {
      console.error('Ошибка загрузки постов:', error);
    }
  }, [type]);

  useEffect(() => {
    setPosts([]);
    setLoading(true);
    fetchPosts();
  }, [fetchPosts]);

  const lastPostElementRef = useCallback(
    node => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        ([entry]) => entry.isIntersecting && fetchPosts(posts.length),
        { threshold: 0.1 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, posts.length, fetchPosts]
  );

  const handleLike = async (postId, liked) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const url = `/api/${liked ? 'unlike' : 'like'}/${postId}`;
      
      await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });

      setPosts(prev => prev.map(post => 
        post.id === postId ? { 
          ...post, 
          liked: !liked, 
          countLike: liked ? post.countLike - 1 : post.countLike + 1 
        } : post
      ));

      setHearthAnimations(prev => ({ ...prev, [postId]: true }));
      setTimeout(() => setHearthAnimations(prev => ({ ...prev, [postId]: false })), 800);
    } catch (error) {
      console.error('Ошибка лайка:', error);
    }
  };

  const openComments = postId => setCommentPostId(postId);
  const closeComments = () => setCommentPostId(null);
  
  const toggleExpandPost = postId => 
    setExpandedPosts(prev => 
      prev.includes(postId) ? prev.filter(id => id !== postId) : [...prev, postId]
    );

  const openLikedUsers = postId => setLikedPostId(postId);
  const closeLikedUsers = () => setLikedPostId(null);

  useEffect(() => {
    posts.forEach((post) => {
        if (post.countLike > 0 && !likedUsersMap[post.id]) {
            axios.get(`/api/likes/${post.id}`)
                .then(res => {
                    setLikedUsersMap(prev => ({
                        ...prev,
                        [post.id]: res.data.users
                    }));
                })
                .catch(error => console.error('Ошибка при получении лайкнувших:', error));
        }
    });
}, [posts, likedUsersMap]);

  if (loading) return <div className="loading">Загрузка...</div>;
  if (!posts.length) return <div className="no-posts">Нет постов</div>;

  return (
    <div className="Hosting">
      {posts.map((post, index) => (
        <div 
          key={post.id}
          ref={index === posts.length - 1 ? lastPostElementRef : null}
          className="post-container"
        >
          <div className="postPage">
            <div className="postPage-header">
              <div className="postHeader-login">
                <div className="postHeader-loginContainer">
                  <img src={post.user.avatarUrl} alt="Аватар" />
                </div>
                <Link to={`/userProfile/${post.user.id}`}>
                  <span>{post.user.login}</span>
                </Link>
              </div>
            </div>

            <div className="postPage-main">
              <div 
                className="double-tap-overlay"
                onDoubleClick={() => !post.liked && handleLike(post.id, post.liked)}
              >
                {hearthAnimations[post.id] && <FaHeart className="heart-animation" />}
              </div>

              {post.fileType.startsWith('image') ? (
                <img
                  src={post.postUrl}
                  alt="Пост"
                  onDoubleClick={() => !post.liked && handleLike(post.id, post.liked)}
                />
              ) : (
                <CustomVideoPlayer
                  src={post.postUrl}
                  type={post.fileType}
                  onDoubleClick={() => !post.liked && handleLike(post.id, post.liked)}
                />
              )}
            </div>

            <div className="postPage-footer">
              <div className="pageFooter-countsInf">
                <nav>
                  <img
                    src={post.liked ? ActiveLike : UnactiveLike}
                    alt="Лайк"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLike(post.id, post.liked);
                    }}
                  />
                  <span onClick={() => openLikedUsers(post.id)}>
                    {post.countLike}
                  </span>
                </nav>
                <nav>
                  <img 
                    src={CommentIcon} 
                    alt="Комментарий" 
                    onClick={() => openComments(post.id)} 
                  />
                  <span>{post.countComment}</span>
                </nav>
              </div>

              <div className="pageFooter-underText">
                <p
                  className={`pageFooter-paragraph ${expandedPosts.includes(post.id) ? 'expanded' : ''}`}
                  onClick={() => toggleExpandPost(post.id)}
                >
                  {post.description}
                </p>

                {likedUsersMap[post.id]?.length > 0 && (
                  <div className="liked-users">
                    Нравится: {likedUsersMap[post.id].slice(0, 2).map((user, i) => (
                      <React.Fragment key={user.id}>
                        <Link to={`/userProfile/${user.id}`}>{user.login}</Link>
                        {i === 0 && ', '}
                      </React.Fragment>
                    ))}
                    {post.countLike > 2 && (
                      <span onClick={() => openLikedUsers(post.id)}>
                        ... и ещё {post.countLike - 2}
                      </span>
                    )}
                  </div>
                )}

                <p className="postDate">
                  {formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {commentPostId && <CommentModal postId={commentPostId} onClose={closeComments} />}
      {likedPostId && <LikedUsersModal postId={likedPostId} onClose={closeLikedUsers} />}
    </div>
  );
}

export default Hosting;