import './createPhoto.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCamera, FaUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import BottomNav from '../bottomNav/BottomNav';

function CreatePost() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [login, setLogin] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();
  const goMainPage = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const fetchData = async () => {
      try {
        const res = await axios.get('/api/user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLogin(res.data);
      } catch (error) {
        console.log('Ошибка при загрузке данных пользователя');
      }
    };
    fetchData();
  }, []);

  if (login === null) {
    return <h1>Loading</h1>;
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile.type.startsWith('image') && !selectedFile.type.startsWith('video')) {
      setError('Допускаются только изображения и видеофайлы.');
      return;
    }

    setFile(selectedFile);
    setError('');

    const filePreview = URL.createObjectURL(selectedFile);
    setPreview(filePreview);

    if (selectedFile.type.startsWith('video')) {
      const videoElement = document.createElement('video');
      videoElement.src = filePreview;
      videoElement.onloadedmetadata = () => {
        if (videoElement.duration > 60) {
          setFile(null);
          setPreview(null);
          setError('Видео должно быть не длиннее 1 минуты.');
        }
      };
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('text', description);
    formData.append('login', login.login);

    try {
      setIsUploading(true);
      const response = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      goMainPage();
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <div className="insta-container">
        <div className="insta-card">
          <div className="insta-header">
            <h2>Создать пост</h2>
          </div>
          <div className="insta-upload">
            <label htmlFor="file-upload" className="insta-upload-label">
              <FaCamera className="insta-upload-icon" size={40} />
              <span>Выберите фото или видео</span>
              <input type="file" id="file-upload" onChange={handleFileChange} />
            </label>
            {error && <p className="insta-error">{error}</p>}
          </div>
          {preview && (
            <div className="insta-preview-section">
              <div className="insta-preview">
                {file.type.startsWith('image') ? (
                  <img src={preview} alt="Preview" />
                ) : (
                  <video src={preview} controls />
                )}
              </div>
              <textarea
                className="insta-textarea"
                placeholder="Добавьте описание..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <button onClick={handleUpload} className="insta-button" disabled={isUploading}>
                {isUploading ? 'Загрузка...' : (
                  <>
                    <FaUpload size={20} className="insta-button-icon" /> Опубликовать
                  </>
                )}
              </button>
              {isUploading && <progress value={uploadProgress} max="100" className="insta-progress" />}
            </div>
          )}
        </div>
      </div>
    

      <BottomNav/>
    </>
  );
}

export default CreatePost;
