import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './UserRecommendations.scss';

const UserRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [followStatus, setFollowStatus] = useState({}); // объект для отслеживания состояния подписки

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get('/api/user/recommendations', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setRecommendations(response.data.recommendations);
      } catch (error) {
        console.error('Ошибка при загрузке рекомендаций:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  const handleFollow = async (userId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(`/api/follow/${userId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // Обновляем статус для этого пользователя
      setFollowStatus(prev => ({ ...prev, [userId]: true }));
    } catch (error) {
      console.error('Ошибка при подписке:', error);
    }
  };

  if (loading) {
    return <div className="recommendations-loading">Загрузка рекомендаций...</div>;
  }

  return (
    <div className="user-recommendations">
      <h2>Рекомендуемые пользователи</h2>
      {recommendations.length === 0 ? (
        <p>Нет рекомендаций.</p>
      ) : (
        <ul className="recommendations-list">
          {recommendations.map(user => (
            <li key={user.id} className="recommendation-item">
              <Link to={`/userProfile/${user.id}`} className="user-link">
                <img src={user.logoImage} alt={user.login} className="user-avatar" />
                <span className="user-login">{user.login}</span>
              </Link>
              <button 
                className="follow-button" 
                onClick={() => handleFollow(user.id)}
                disabled={followStatus[user.id]}
              >
                {followStatus[user.id] ? 'Подписаны' : 'Подписаться'}
              </button>
              {/* Если вы получаете данные о количестве подписчиков и аватарах подписчиков,
                  их можно отобразить здесь, например:*/
              <div className="followers-info">
                <span>{user.followersCount} подписчиков</span>
                <div className="followers-avatars">
                  {user.Followers && user.Followers.slice(0,3).map(follower => (
                    <img key={follower.id} src={follower.logoImage} alt={follower.login} />
                  ))}
                </div>
              </div>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserRecommendations;
