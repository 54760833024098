import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaRegCompass, FaPlusSquare, FaUser } from 'react-icons/fa';
import './BottomNav.scss';

const BottomNav = () => {
  const location = useLocation();
  
  // Функция для определения, активен ли пункт меню
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bottom-nav">
      <Link to="/subscriptions" className={`nav-item ${isActive('/subscriptions') ? 'active' : ''}`}>
        <FaHome size={24} />
        <span>Главная</span>
      </Link>
      <Link to="/" className={`nav-item ${isActive('/') ? 'active' : ''}`}>
        <FaRegCompass size={24} />
        <span>Рекомендации</span>
      </Link>
      <Link to="/createPost" className={`nav-item ${isActive('/createPost') ? 'active' : ''}`}>
        <FaPlusSquare size={24} />
        <span>Новый пост</span>
      </Link>
      <Link to="/myProfile" className={`nav-item ${isActive('/myProfile') ? 'active' : ''}`}>
        <FaUser size={24} />
        <span>Профиль</span>
      </Link>
    </nav>
  );
};

export default BottomNav;
