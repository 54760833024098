import axios from 'axios';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CommentModal from '../../Hosting/coment/comment';
import CommentIcon from '../../Hosting/image/commentIcon.png';
import ActiveLike from '../../Hosting/image/activeLike.png';
import UnactiveLike from '../../Hosting/image/unactiveLike.png';
import { formatDistanceToNow } from 'date-fns';
import LikedUsersModal from '../../../likeModal/likeModal';

function CustomVideoPlayer({ src, type }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  return (
    <div
      className="custom-video"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <video
        ref={videoRef}
        muted={isMuted}
        onClick={togglePlayPause}
        loop
        playsInline
        webkit-playsinline="true"
      >
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
      <div className={`video-controls ${showControls ? 'visible' : ''}`}>
        <button className="play-pause" onClick={togglePlayPause}>
          {isPlaying ? '❚❚' : '►'}
        </button>
        <button className="volume" onClick={toggleMute}>
          {isMuted ? '🔇' : '🔊'}
        </button>
      </div>
    </div>
  );
}

function RecBlock({ type }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentPostId, setCommentPostId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [expandedPosts, setExpandedPosts] = useState([]);
  const [likedPostId, setLikedPostId] = useState(null);
  // Состояние для хранения списка лайкнувших для каждого поста
  const [likedUsersMap, setLikedUsersMap] = useState({});
  const observer = useRef();

  const fetchPosts = useCallback(async (skip = 0) => {
    try {
      const token = localStorage.getItem('jwtToken');
      // Обращаемся к эндпоинту рекомендаций вместо /api/posts
      const res = await axios.get('/api/recommendations', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          skip,
          limit: 10,
          type // если нужно передавать type для фильтрации внутри recommendations
        }
      });

      // Предполагается, что сервер возвращает { recommendations: [...], user: ... }
      setPosts(prevPosts => {
        const newPosts = [...prevPosts, ...res.data.recommendations];
        const uniquePosts = Array.from(new Set(newPosts.map(post => post.id)))
          .map(id => newPosts.find(post => post.id === id));
        return uniquePosts;
      });
      setLoading(false);

      if (res.data.recommendations.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [type]);

  useEffect(() => {
    setPosts([]);
    setLoading(true);
    fetchPosts();
  }, [fetchPosts]);

  const lastPostElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchPosts(posts.length);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore, fetchPosts, posts.length]);

  const handleLike = async (postId, liked) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const url = liked ? `/api/unlike/${postId}` : `/api/like/${postId}`;

      await axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPosts((prevPosts) => {
        return prevPosts.map((post) =>
          post.id === postId
            ? { ...post, liked: !liked, countLike: liked ? post.countLike - 1 : post.countLike + 1 }
            : post
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openComments = (postId) => {
    setCommentPostId(postId);
  };

  const closeComments = () => {
    setCommentPostId(null);
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpandedPosts) =>
      prevExpandedPosts.includes(postId)
        ? prevExpandedPosts.filter(id => id !== postId)
        : [...prevExpandedPosts, postId]
    );
  };

  // Функции для открытия/закрытия модального окна лайкнувших
  const openLikedUsers = (postId) => {
    setLikedPostId(postId);
  };

  const closeLikedUsers = () => {
    setLikedPostId(null);
  };

  // При изменении списка постов, для каждого поста с лайками загружаем первых двух пользователей
  useEffect(() => {
    posts.forEach((post) => {
      if (post.countLike > 0 && !likedUsersMap[post.id]) {
        axios.get(`/api/likes/${post.id}`)
          .then(res => {
            // res.data содержит объект { users: [...] }
            setLikedUsersMap(prev => ({ ...prev, [post.id]: res.data.users }));
          })
          .catch(error => console.error('Ошибка при получении лайкнувших:', error));
      }
    });
  }, [posts, likedUsersMap]);

  if (loading) {
    return <h1>Загрузка материала</h1>;
  }

  if (!posts.length) {
    return <h1>Нет постов</h1>;
  }

  return (
    <div className="Hosting">
      {posts.map((post, index) => (
        <div key={post.id} ref={index === posts.length - 1 ? lastPostElementRef : null}>
          <div className="postPage">
            <div className="postPage-header">
              <div className="postHeader-login">
                <div className="postHeader-loginContainer">
                  <img src={post.user.avatarUrl} alt="User Avatar" />
                </div>
                <Link to={`/userProfile/${post.user.id}`}>
                  <span>{post.user.login}</span>
                </Link>
              </div>
              <div></div>
            </div>
            <div className="postPage-main">
              {post.fileType.startsWith('image') ? (
                <img src={post.postUrl} alt="Post Image" />
              ) : post.fileType.startsWith('video') ? (
                <CustomVideoPlayer src={post.postUrl} type={post.fileType} />
              ) : null}
            </div>
            <div className="postPage-footer">
              <div className="pageFooter-countsInf">
                <nav onClick={() => openLikedUsers(post.id)}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLike(post.id, post.liked);
                    }}
                    src={post.liked ? ActiveLike : UnactiveLike}
                    alt="Like Icon"
                  />
                  <span>{post.countLike}</span>
                </nav>
                <nav>
                  <img src={CommentIcon} alt="Comment Icon" onClick={() => openComments(post.id)} />
                  <span>{post.countComment}</span>
                </nav>
              </div>
              <div className="pageFooter-underText">
                <p
                  className='pageFooter-paragraph'
                  onClick={() => toggleExpandPost(post.id)}
                  style={{
                    overflow: expandedPosts.includes(post.id) ? 'visible' : 'hidden',
                    whiteSpace: expandedPosts.includes(post.id) ? 'normal' : 'nowrap',
                    textOverflow: expandedPosts.includes(post.id) ? 'clip' : 'ellipsis'
                  }}
                >
                  {post.description}
                </p>
                {likedUsersMap[post.id] && likedUsersMap[post.id].length > 0 && (
                  <div className="liked-users">
                    <span>Нравится: </span>
                    {likedUsersMap[post.id].slice(0, 2).map((user, index) => (
                      <span key={user.id}>
                        <Link to={`/userProfile/${user.id}`}>{user.login}</Link>
                        {index === 0 && likedUsersMap[post.id].length > 1 ? ', ' : ''}
                      </span>
                    ))}
                    {likedUsersMap[post.id].length > 2 && (
                      <span onClick={() => openLikedUsers(post.id)} style={{ cursor: 'pointer' }}>
                        ... и еще {post.countLike - 2}
                      </span>
                    )}
                  </div>
                )}
                <p className="postDate">
                  {isNaN(new Date(post.createdAt)) ? 'Invalid date' : formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      {commentPostId && (
        <CommentModal postId={commentPostId} onClose={closeComments} />
      )}
      {likedPostId && (
        <LikedUsersModal postId={likedPostId} onClose={closeLikedUsers} />
      )}
    </div>
  );
}

export default RecBlock;
