import React, { useState } from 'react';
import axios from 'axios';
import { FaCamera, FaFolderOpen, FaTimes } from 'react-icons/fa';
import './storyUpload.scss';

const StoryUpload = ({ onClose }) => {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewUrl(URL.createObjectURL(selectedFile));
        }
    };

    const openCamera = () => {
        alert('Функция открытия камеры пока не реализована');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!file) {
            setError('Выберите файл для загрузки');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('text', text);
    
        try {
            const token = localStorage.getItem('jwtToken');
            await axios.post('/api/story/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
            });
            onClose();
        } catch (error) {
            console.error('Ошибка загрузки сториса:', error);
            setError('Ошибка при загрузке сториса');
        }
    };

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="story-upload">
                <div className="modal-header">
                    <h2>Создать историю</h2>
                    <button className="close-btn" onClick={onClose}>
                        <FaTimes />
                    </button>
                </div>
                
                {error && (
                    <div className="error-message">
                        <span>!</span>
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="upload-section">
                        <div className="upload-controls">
                            <label className="file-input-label">
                                <div className="icon-wrapper">
                                    <FaFolderOpen className="icon" />
                                </div>
                                <span>Галерея</span>
                                <input
                                    type="file"
                                    accept="image/*,video/*"
                                    onChange={handleFileChange}
                                    hidden
                                />
                            </label>
                            
                            <div className="vertical-divider"></div>
                            
                            <button 
                                type="button" 
                                className="camera-button"
                                onClick={openCamera}
                            >
                                <div className="icon-wrapper">
                                    <FaCamera className="icon" />
                                </div>
                                <span>Камера</span>
                            </button>
                        </div>

                        {previewUrl && (
                            <div className="preview-container">
                                {file.type.startsWith('video') ? (
                                    <video className="preview" src={previewUrl} controls />
                                ) : (
                                    <img className="preview" src={previewUrl} alt="Превью" />
                                )}
                            </div>
                        )}
                    </div>

                    <div className="text-input">
                        <textarea
                            placeholder=" "
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <label>Добавить текст...</label>
                    </div>

                    <button type="submit" className="upload-button">
                        {uploadProgress > 0 ? (
                            <span>{uploadProgress}%</span>
                        ) : (
                            'Опубликовать'
                        )}
                    </button>
                </form>

                {uploadProgress > 0 && (
                    <div className="progress-container">
                        <div 
                            className="progress-bar"
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                )}
            </div>
        </>
    );
};

export default StoryUpload;