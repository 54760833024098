import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoryViewer.scss';

const StoryViewer = ({ stories, userId, login, logoImage, onClose, onNext, onPrevious, nextUserStories }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [isPaused, setIsPaused] = useState(false); // Флаг для паузы
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const currentStory = stories[currentStoryIndex];

  useEffect(() => {
    if (!currentStory || isPaused) return; // Не обновляем прогресс, если на паузе

    const totalStories = stories.length;
    const initialProgress = new Array(totalStories).fill(0);
    setProgress(initialProgress);

    const storyDuration = currentStory.fileType.startsWith('image') ? 5000 : null; // Для видео будет управляться встроенно
    const updateInterval = 100;
    const progressStep = (100 / (storyDuration / updateInterval));

    if (storyDuration) {
      intervalRef.current = setInterval(() => {
        setProgress((prev) => {
          const updatedProgress = [...prev];
          updatedProgress[currentStoryIndex] += progressStep;

          if (updatedProgress[currentStoryIndex] >= 100) {
            clearInterval(intervalRef.current);
            handleNextStory();
          }

          return updatedProgress;
        });
      }, updateInterval);
    }

    return () => clearInterval(intervalRef.current);
  }, [currentStoryIndex, stories, isPaused]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex((prevIndex) => prevIndex + 1);
    } else {
      if (nextUserStories) {
        onNext(nextUserStories);
      } else {
        onNext();
      }
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex((prevIndex) => prevIndex - 1);
    } else {
      onPrevious();
    }
  };

  const handleContentClick = (e) => {
    const { clientX, currentTarget } = e;
    const { offsetWidth } = currentTarget;

    if (clientX < offsetWidth / 2) {
      handlePreviousStory();
    } else {
      handleNextStory();
    }
  };

  const handleVideoEnded = () => {
    handleNextStory();
  };

  const handleProfileRedirect = () => {
    navigate(`/userProfile/${userId}`);
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const storyDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - storyDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} секунд назад`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} минут назад`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} часов назад`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} дней назад`;
    }
  };

  const pauseStory = () => {
    setIsPaused(true);
    clearInterval(intervalRef.current); // Останавливаем прогресс
  };

  const resumeStory = () => {
    setIsPaused(false); // Снова запускаем прогресс
  };

  useEffect(() => {
    setCurrentStoryIndex(0);
  }, [stories]);

  if (!currentStory) {
    return null;
  }

  return (
    <div className="story-viewer">
      {/* Шапка */}
      <div className="story-header">
        <div className="user-info" onClick={handleProfileRedirect}>
          <div className="avatar-container">
            <img src={logoImage} alt={login} className="avatar" />
          </div>
          <div className="user-details">
            <span className="login">{login}</span>
            <span className="time-ago">{getTimeAgo(currentStory.createdAt)}</span>
          </div>
        </div>
        <button className="close-btn" onClick={onClose}>
          <svg viewBox="0 0 24 24">
            <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </button>
      </div>

      {/* Прогресс-бар */}
      <div className="progress-container">
        {stories.map((_, index) => (
          <div key={index} className="progress-track">
            <div className="progress-bar" style={{ width: `${progress[index]}%` }} />
          </div>
        ))}
      </div>

      {/* Контент с навигацией */}
      <div className="content-wrapper">
        <div 
          className="nav-area prev" 
          onClick={handlePreviousStory}
        />
        
        <div 
          className="story-content"
          onClick={handleContentClick}
          onMouseDown={pauseStory}
          onMouseUp={resumeStory}
          onTouchStart={pauseStory}
          onTouchEnd={resumeStory}
        >
          {currentStory.fileType.startsWith('image') ? (
            <img
              src={currentStory.fileUrl}
              alt="Story"
              className="story-media"
              style={{ opacity: isPaused ? 0.8 : 1 }}
            />
          ) : (
            <video
              src={currentStory.fileUrl}
              className="story-media"
              autoPlay={!isPaused}
              muted
              playsInline
              onEnded={handleVideoEnded}
            />
          )}
        </div>

        <div 
          className="nav-area next" 
          onClick={handleNextStory}
        />
      </div>

      {/* Текст */}
      <div className="story-text">
        <p>{currentStory.text}</p>
      </div>
    </div>
  );
};

export default StoryViewer;
