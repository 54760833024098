import React from 'react';
import Hosting from '../Hosting/hosting';
import Header from '../../header/header';
import Nav from '../../navElements/nav';
import StoryList from "../../storyes/storyList/storyList";
import BottomNav from '../../bottomNav/BottomNav';
import UserRecommendations from '../../userRecommendations/UserRecommendations';

function SubscribesPosts() {
    return (
        <>
        <Header/>
        <StoryList/>
        <Nav/>
        <UserRecommendations/>
        <Hosting type="subscribesPosts" />
        <BottomNav/>
        </>
    );
}

export default SubscribesPosts;
