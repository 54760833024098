import './comment.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import PostNav from './img/navigate.png';
import SendButton from './img/sendButton.png';
import { FaPaperPlane } from 'react-icons/fa';

function CommentModal({ postId, onClose }) {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [userId, setUserId] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const res = await axios.get(`/api/posts/${postId}/comments`);
                const commentsWithUser = res.data.map(comment => ({
                    ...comment,
                    user: comment.User || { login: 'Unknown User', logoImage: '' }
                }));
                console.log('Comments with User Data:', commentsWithUser); // Логирование данных
                setComments(commentsWithUser);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchUserId = () => {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                const decodedToken = jwtDecode(token);
                setUserId(decodedToken.id);
            }
        };

        fetchComments();
        fetchUserId();
    }, [postId]);

    useEffect(() => {
        console.log(userId);
    }, [userId]);

    const handleAddComment = async () => {
        try {
            if(!newComment){
                return false
            }

            const token = localStorage.getItem('jwtToken');
            const res = await axios.post(
                `/api/posts/${postId}/comments`,
                { text: newComment },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            // Обновите список комментариев после добавления нового комментария
            const newCommentData = res.data;
            setComments(prevComments => [
                {
                    ...newCommentData,
                    user: {
                        login: newCommentData.User.login,
                        logoImage: newCommentData.User.logoImage
                    }
                },
                ...prevComments
            ]);

            

            setNewComment('');
            setIsSending(true)

            setTimeout(() => setIsSending(false), 1000)
        } catch (error) {
            console.error('Ошибка при добавлении комментария', error);
        }
    };

    const handleDeleteComment = async () => {
        if (commentToDelete) {
            try {
                const token = localStorage.getItem('jwtToken');
                await axios.delete(`/api/comments/${commentToDelete}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setComments(prevComments => prevComments.filter(comment => comment.id !== commentToDelete));
                setCommentToDelete(null);
            } catch (error) {
                console.error('Ошибка при удалении комментария', error);
            }
        }
    };

    const toggleMenu = (commentId) => {
        if (commentId === commentToDelete) {
            setCommentToDelete(null); // Закрываем меню, если нажали повторно
        } else {
            setCommentToDelete(commentId); // Открываем меню для выбранного комментария
        }
    };

    return (
        <div className="comment-modal">
            <div className="comment-modal-content">
                <button className="close-button" onClick={onClose}>X</button>
                <div className="comments-section">
                    <div className="comments-list">
                        {comments.map((comment) => (
                            <div key={comment.id} className="comment">
                                <div className='commentMain'>
                                    {comment.user ? (
                                        <>
                                            <img src={comment.user.logoImage || 'default-avatar.png'} alt="User Avatar" />
                                            <div className='commentContent'>
                                                <strong>{comment.user.login}</strong> <nav>{comment.commentText}</nav>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='commentContent'>
                                            <strong>Unknown User</strong> <nav>{comment.commentText}</nav>
                                        </div>
                                    )}
                                </div>
                                {comment.userId === userId && (
                                    <div className='deleteComment'>
                                        <img src={PostNav} alt="nav" onClick={() => toggleMenu(comment.id)}/>
                                        {commentToDelete === comment.id && (
                                            <div className='confirmation'>
                                                <button onClick={handleDeleteComment} className='deleteButton'>Удалить</button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="add-comment">
                        <input
                            type="text"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Добавить комментарий"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') handleAddComment();
                            }}
                        />
                        <button 
                            className="send-button" 
                            onClick={handleAddComment} 
                            disabled={isSending}
                        >
                            <FaPaperPlane className="send-icon" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommentModal;
