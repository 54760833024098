import Header from "../../header/header";
import Nav from "../../navElements/nav";
import BottomNav from "../../bottomNav/BottomNav";
import RecBlock from "./recBlock/recBlock";
import UserRecommendations from "../../userRecommendations/UserRecommendations";

function Recomendations() {
    return (
        <>
            <Header></Header>
            <Nav></Nav>
            <UserRecommendations/>
            <RecBlock></RecBlock>
            <BottomNav></BottomNav>
        </>
    )
}

export default Recomendations;