import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './MyStories.scss';

const MyStories = ({ stories, userId, login, logoImage, onClose, onNext, onPrevious, onDeleteStory }) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [storyDuration, setStoryDuration] = useState(null);
  const [viewers, setViewers] = useState([]);
  const [viewersModalOpen, setViewersModalOpen] = useState(false);
  const navigate = useNavigate();
  const intervalRef = useRef(null);

  const currentStory = stories[currentStoryIndex];

  useEffect(() => {
    setContentLoaded(false);
    setStoryDuration(null);
    setProgress(new Array(stories.length).fill(0));
  }, [currentStoryIndex, stories]);

  useEffect(() => {
    if (!currentStory) return;
    const fetchViewers = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const res = await axios.get(`/api/story/${currentStory.id}/views`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setViewers(res.data.viewers);
      } catch (error) {
        console.error("Ошибка получения просмотров:", error);
      }
    };
    fetchViewers();
  }, [currentStory]);

  useEffect(() => {
    if (!currentStory || isPaused || !contentLoaded || !storyDuration) return;

    const updateInterval = 100;
    const progressStep = 100 / (storyDuration / updateInterval);

    intervalRef.current = setInterval(() => {
      setProgress(prev => {
        const updated = [...prev];
        updated[currentStoryIndex] += progressStep;
        if (updated[currentStoryIndex] >= 100) {
          clearInterval(intervalRef.current);
          handleNextStory();
        }
        return updated;
      });
    }, updateInterval);

    return () => clearInterval(intervalRef.current);
  }, [currentStoryIndex, isPaused, contentLoaded, storyDuration]);

  const handleNextStory = () => {
    if (currentStoryIndex < stories.length - 1) {
      setCurrentStoryIndex(prev => prev + 1);
    } else {
      onClose();
    }
  };

  const handlePreviousStory = () => {
    if (currentStoryIndex > 0) {
      setCurrentStoryIndex(prev => prev - 1);
    } else {
      onPrevious();
    }
  };

  const handlePause = () => {
    clearInterval(intervalRef.current);
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  const handleContentClick = (e) => {
    const { clientX, currentTarget } = e;
    clientX < currentTarget.offsetWidth / 2 ? handlePreviousStory() : handleNextStory();
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
    setStoryToDelete(currentStory.id);
  };

  const confirmDelete = () => {
    onDeleteStory(storyToDelete);
    setShowDeleteConfirmation(false);
    handleNextStory();
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const diffSeconds = Math.floor((now - new Date(timestamp)) / 1000); // Исправлено здесь
  
    if (diffSeconds < 60) {
      return `${diffSeconds} сек. назад`;
    } else if (diffSeconds < 3600) {
      const minutes = Math.floor(diffSeconds / 60);
      return `${minutes} мин. назад`;
    } else if (diffSeconds < 86400) {
      const hours = Math.floor(diffSeconds / 3600);
      return `${hours} ч. назад`;
    } else {
      const days = Math.floor(diffSeconds / 86400);
      return `${days} дн. назад`;
    }
  };

  const openViewersModal = () => {
    handlePause();
    setViewersModalOpen(true);
  };

  const closeViewersModal = () => {
    handleResume();
    setViewersModalOpen(false);
  };

  if (!currentStory) return null;

  return (
    <div className="my-stories">

      <div className="stories-header">
        <div className="user-info">
          <div className="avatar-container">
            <img src={logoImage} alt={login} className="user-avatar" />
          </div>
          <div className="user-details">
            <span className="username">{login}</span>
            <span className="timestamp">{getTimeAgo(currentStory.createdAt)}</span>
          </div>
        </div>
        <button className="close-btn" onClick={onClose}>
          <svg viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </button>
      </div>

      {/* Прогресс-бар */}
      <div className="progress-container">
        {stories.map((_, i) => (
          <div key={i} className="progress-track">
            <div 
              className="progress-bar" 
              style={{ width: `${Math.min(progress[i], 100)}%` }}
            />
          </div>
        ))}
      </div>

      {/* Контент */}
      <div className="content-wrapper">
        <div 
          className="story-content"
          onClick={handleContentClick}
          onMouseDown={handlePause}
          onMouseUp={handleResume}
          onTouchStart={handlePause}
          onTouchEnd={handleResume}
        >
          {currentStory.fileType.startsWith('image') ? (
            <img
              src={currentStory.fileUrl}
              alt="Story"
              onLoad={() => setContentLoaded(true)}
            />
          ) : (
            <video
              src={currentStory.fileUrl}
              autoPlay={!isPaused}
              playsInline
              onLoadedMetadata={(e) => {
                setContentLoaded(true);
                setStoryDuration(e.target.duration * 1000);
              }}
              onEnded={handleNextStory}
            />
          )}
        </div>
      </div>

      {/* Элементы управления */}
      <div className="controls-layer">
        {viewers.length > 0 && (
          <div className="viewers-preview" onClick={openViewersModal}>
            <div className="preview-avatars">
              {viewers.slice(0, 2).map(user => (
                <img key={user.id} src={user.logoImage} alt={user.login} />
              ))}
            </div>
            <span className="preview-count">{viewers.length}</span>
          </div>
        )}
        
        <div className="story-footer">
          <p className="story-text">{currentStory.text}</p>
          <button className="more-btn" onClick={handleDeleteConfirmation}>
            <svg viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="2.5"/>
              <circle cx="12" cy="5" r="2.5"/>
              <circle cx="12" cy="19" r="2.5"/>
            </svg>
          </button>
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className="delete-modal">
          <div className="modal-content">
            <p>Удалить эту историю?</p>
            <div className="modal-actions">
              <button className="confirm-btn" onClick={confirmDelete}>Удалить</button>
              <button 
                className="cancel-btn" 
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}

      {viewersModalOpen && (
        <div className="viewers-modal">
          <div className="modal-content">
            {/* Добавлен контейнер для центрирования */}
            <div className="modal-inner">
              <h3>Просмотры ({viewers.length})</h3>
              <ul className="viewers-list">
                {viewers.map(user => (
                  <li key={user.id}>
                    <Link to={`/user/${user.id}`} className="viewer-item">
                      <img src={user.logoImage} alt={user.login} />
                      <span>{user.login}</span>
                    </Link>
                  </li>
                ))}
              </ul>
              <button className="close-modal" onClick={closeViewersModal}>
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyStories;