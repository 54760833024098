import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LikedUsersModal.scss'; // Импортируем стили
import { Link } from 'react-router-dom'

const LikedUsersModal = ({ postId, onClose }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLikedUsers = async () => {
            try {
                const response = await axios.get(`/api/likes/${postId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                setUsers(response.data.users)
            } catch (error) {
                console.error('Ошибка при получении списка лайков:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLikedUsers();
    }, [postId]);

    return (
        <div className="liked-users-modal">
            <div className="modal-content">
                <h3>Понравилось:</h3>
                {loading ? (
                    <div className="loading-state">
                        <p>Загрузка...</p>
                    </div>
                ) : users.length === 0 ? (
                    <p>Никто еще не лайкнул</p>
                ) : (
                    <ul className="liked-users-list">
                        {users.map(user => (
                            <li key={user.id} className="liked-user">
                                <Link to={`/userProfile/${user.id}`} className="liked-user">
                                    <img src={user.logoImage} alt={user.login} className="user-avatar" />
                                    <span className="user-login">{user.login}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
                <button className="close-button" onClick={onClose}>X</button>
            </div>
        </div>
    );
};

export default LikedUsersModal;
